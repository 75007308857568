@import "./style.scss";
@import "./chatui.scss";
@import "./inboxui.scss";

@import "./vendor/antd.scss";

.member {
  .ant-table-expanded-row {
    display: block;
    height: 0;

    td {
      display: block;
      height: 0;
    }
  }

  .ant-table-thead {
    tr {
      background: none !important;
    }
  }

  th {
    .ant-table-column-title {
      padding-left: 15px;
      color: $dark-text;
      font-size: 18px;
      font-family: $font-family-bold;
    }

    .ant-table-column-sorter-inner {
      position: absolute;
      display: flex;

      align-items: center;
      left: 13px;
      height: 53px !important;
      top: 0;
      padding: 0 !important;
      margin: 0 !important;
      flex-direction: column;
      justify-content: center;

      .ant-table-column-sorter-down.on,
      .ant-table-column-sorter-up.on {
        color: $dark-text !important;
      }
    }
  }

  td.ant-table-column-has-actions:nth-child(2) {
    height: 75px;
    align-items: center;
  }

  .ant-table-tbody>tr>td {
    border: none !important;
    height: 75px;
  }
}

.ant-table-tbody {
  .ant-table-row:nth-child() {
    background: $gray-500;
  }

  .ant-table-row:nth-child() {
    background: $white;
  }
}

.ant-table-expand-icon-th {
  display: none;
}

.ant-table-row {
  position: relative;

  .ant-table-row-expand-icon-cell {
    position: ABSOLUTE;
    z-index: 99;
    width: 100%;
    height: 75px;

    .ant-table-row-expand-icon {
      position: ABSOLUTE;
      width: 100%;
      height: 75px;
      z-index: 99;
      left: 0;
      right: 0;
      opacity: 0;
    }
  }

  &:hover {
    .ant-table-row-expand-icon-cell {
      background: transparent !important;
    }
  }
}

.ant-menu-item {
  color: black;
}

.ant-menu-item-active {
  background-color: lightgrey;
}

.table-col {
  word-break: break-word;
  max-width: 300px;
}

.over-sized {
  max-width: 100%;
  word-break: break-word;
}

.group-activity {
  position: relative;

  .fa {
    margin-right: 10px;
  }
}

.login-page {
  background: $dark-bg;
  height: 100vh;

  header {
    display: none;
  }
}

.nav-item.active {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: $black;
    bottom: -10px;
  }
}

.ant-table-expanded-row {
  height: 0;

  td {
    height: 0;
    padding: 0 !important;
  }
}

.ant-table-expanded-row {
  .user-sidebar {
    &.hide {
      display: none !important;
    }
  }
}



.selection-container {
  background-color: white;
  overflow-y: scroll;
  height: 250px;

  .menu {
    display: contents;

    .menu-item {
      width: 100%;
      border-bottom: 1px solid lightgray;
    }
  }
}



.ant-select {
  i {
    &:before {
      margin-right: 8px;
      font-size: 11px;
    }
  }
}

.left-conatiner {
  text-align: center;
}


.ant-select-dropdown-menu {
  li {
    i {
      &:before {
        margin-right: 8px;
        font-size: 11px;
      }
    }
  }
}

.observe:hover {
  background: #d8d8d8;
}

.observe-item {
  background: #4168ca;
  opacity: 0.1;
  color: #4168ca;
}